import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "name": "Technology",
  "route": "/technology"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Technology`}</h1>
    <p>{`Smartlike builds its network on a validating DHT(decentralized Hash Table) with a set of incentives that models real world financial interactions with local consensus instead of focusing on complete trustless operations.`}</p>
    <p>{`We can achieve a much larger scale when we incentivize cooperation and make use of existing trusted relationships. There's no single blockchain but rather a cryptographically chained graph of transactions. All agents (nodes or apps running on servers, browsers and smartphones) cross-validate and store relevant transaction history with some redundancy. This allows unlimited bandwidth. Although it requires an exception handling mechanism in contrast to an all-or-nothing state of blockchain systems, it is much cheaper to provide methods to repair/abort/cancel transactions than to seek global consistency (one truth for all). We currently build with `}<a parentName="p" {...{
        "href": "https://github.com/holochain/holochain"
      }}>{`Holochain's DHT`}</a>{` and would like to cooperate with them when they launch beta.`}</p>
    <h2>{`Beta`}</h2>
    <p>{`In beta we ship all features (privacy, feeless, financial decentralization, etc.) but technical decentralization. It will just take more time and resources to complete. In the meantime, users can enjoy the beta built with the industry proven Kafka/Cassandra/Rust tech.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      